const statistic = {
  state: {
    beginUpdate: '',
    publishDate: '',
    mediaType: 0,
    adType: 0,
    fileId: null,
    advertiserId: null,
    brandId: null,
    keyword: ''
  },
  mutations: {
    set_statistic_update (state, data) {
      state.beginUpdate = data
    },
    set_statistic_publishDate (state, data) {
      state.publishDate = data
    },
    set_statistic_mediaType (state, data) {
      state.mediaType = data
    },
    set_statistic_adType (state, data) {
      state.adType = data
    },
    set_statistic_advertiserId (state, data) {
      state.advertiserId = data
    },
    set_statistic_brandId (state, data) {
      state.brandId = data
    },
    set_statistic_fileId (state, data) {
      state.fileId = data
    },
    set_statistic_keyword (state, data) {
      state.keyword = data
    }

  },
  getters: {

  },
  actions: {

  }
}

export default statistic
