<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bg_map">
        <component :is="curMapComponent" ></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import statisticStoreModule from '@/store/modules/statistic'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    CenterComponent: () => import('./components/Center'),
    LeftComponent: () => import('./components/Left'),
    RightComponent: () => import('./components/Right')
  },
  created () {
    if (!this.$store.hasModule('statistic')) {
      this.$store.registerModule('statistic', statisticStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('0')
    this.setShowMap(true)

    this.setLeftComponent('LeftComponent')
    this.setRightComponent('RightComponent')
    this.setMapComponent('CenterComponent')
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('statistic')

    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
